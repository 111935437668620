<template>
  <div class="reset-password-dialog">
    <h2 class="text-center text-body-1 font-weight-light reset-password-dialog__subtitle">
      {{ $trans("reset_password_subtitle") }}
    </h2>
    <reset-password-dialog-form class="reset-password-dialog__form"  />
  </div>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DialogMixin from '@/components/dialogs/mixins/DialogMixins'
import ResetPasswordDialogForm from '@/components/dialogs/components/ResetPasswordDialog/ResetPasswordDialogForm'

export default mixins(DialogMixin).extend({
  name: 'ResetPasswordDialog',
  components: {
    ResetPasswordDialogForm
  }
})
</script>

<style lang="scss" scoped>
.reset-password-dialog__subtitle {
  padding: 0 10px;
}
.reset-password-dialog__form {
  padding: 0 10px;
}

@media (min-width: 960px) {
  .reset-password-dialog__subtitle {
    margin-bottom: 10px;
    padding: 0 65px;
  }

  .reset-password-dialog__form {
    padding: 0 65px;
  }
}
</style>
