<template>
  <v-form
    id="reset-password-dialog-form"
    ref="form"
    @submit.prevent="handleLogin"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.password"
            :autofocus="true"
            :label="$trans('password')"
            :rules="[rules.required, rules.minChars(8), rules.maxChars(255), rules.lowercase, rules.uppercase, rules.number, rules.specialChar]"
            :append-icon="showPassword ? '$eye_on' : '$eye_off'"
            :type="showPassword ? 'text' : 'password'"
            outlined
            class="password"
            :disabled="isLoading"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password"
          >
            <v-icon
              slot="prepend"
              class="field-icon"
            >
              $password
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="error && error.length > 0">
        <v-col cols="12">
          <v-alert type="error">
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          cols="12"
          class="sign_up-form__description"
        >
          <c-info-alert class="mb-2">
            {{ $trans('password_information') }}
          </c-info-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            color="primary"
            type="submit"
            form="reset-password-dialog-form"
            height="56px"
            depressed
            :loading="isLoading"
            :disabled="isLoading"
          >
            {{ $trans("reset_password_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DialogMixin from '@/components/dialogs/mixins/DialogMixins'
import CInfoAlert from '@/components/CInfoAlert'
import { required, minChars, maxChars, lowercase, uppercase, number, specialChar } from '@/calendesk/forms/validators'
import { mapActions } from 'vuex'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import ResetPasswordRequestData from '@/calendesk/models/DTO/Request/ResetPasswordRequestData'

export default mixins(DialogMixin).extend({
  name: 'ResetPasswordDialogForm',
  components: { CInfoAlert },
  data: () => ({
    isLoading: false,
    error: null,
    showPassword: false,
    form: {
      password: null
    },
    rules: {
      required,
      minChars,
      lowercase,
      uppercase,
      number,
      specialChar,
      maxChars
    }
  }),
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword'
    }),
    handleLogin () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      const data = new ResetPasswordRequestData(
        this.form.password,
        this.form.password,
        this.dialog.data.token
      )
      this.resetPassword(data).then(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.CONFIRMATION_DIALOG,
          this.$trans('reset_password_success_title'),
          DialogSize.SMALL,
          false,
          'center'))
      })
        .catch(error => {
          switch (error.response.status) {
            case 422:
              this.error = this.$trans('reset_password_fail_text')
              break
            default:
              this.error = this.$trans('error_occurred')
              break
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
})
</script>

<style lang="scss" scoped>
.field-icon {
  width: 40px;
  justify-content: start;
  color: var(--v-primary-base);
}

.error--text {
  .field-icon {
    color: var(--v-error-base);
  }
}
</style>
