export default class ResetPasswordRequestData {
  public password: string
  public password_confirmation: string
  public token: string

  public constructor (password: string, passwordConfirmation: string, token: string) {
    this.password = password
    this.password_confirmation = passwordConfirmation
    this.token = token
  }
}
